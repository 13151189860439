import { Injectable } from '@angular/core';
import { filterNullUndefined } from '@common/util-foundation';
import {
  ApplianceDetailsConfig,
  JourneyTypeConfig,
  DiscountStoreConfig,
  QuotesConfig,
  SalesSidedoorConfig,
  SidedoorConfig,
  SalesClientConfig,
  ClientConfig,
} from '@common/util-models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FeatureConfigState } from './feature-config.reducers';
import * as FeatureConfigSelectors from './feature-config.selectors';

@Injectable()
export class FeatureConfigFacade {
  remoteStateLoaded$: Observable<boolean> = this.store
    .select(FeatureConfigSelectors.getRemoteStateLoaded)
    .pipe(filter((loaded: boolean) => loaded));

  discountStore$: Observable<
    DiscountStoreConfig | undefined
  > = this.store.select(FeatureConfigSelectors.getDiscountStoreConfig);

  applianceDetails$: Observable<ApplianceDetailsConfig> = this.store
    .select(FeatureConfigSelectors.getApplianceDetailsConfig)
    .pipe(filterNullUndefined());

  quotes$: Observable<QuotesConfig> = this.store
    .select(FeatureConfigSelectors.getQuotesConfig)
    .pipe(filterNullUndefined());

  isEngineerJourney$: Observable<boolean> = this.store
    .select(FeatureConfigSelectors.isEngineerJourney)
    .pipe(filterNullUndefined());

  constructor(private store: Store<FeatureConfigState>) {}

  sidedoor$<T extends SidedoorConfig = SalesSidedoorConfig>(
    filterNullAndUndefinedValues?: true
  ): Observable<T>;

  sidedoor$<T extends SidedoorConfig = SalesSidedoorConfig>(
    filterNullAndUndefinedValues?: false
  ): Observable<T | undefined>;

  sidedoor$<T extends SidedoorConfig = SalesSidedoorConfig>(
    filterNullAndUndefinedValues: boolean = true
  ): Observable<T | undefined> {
    const sidedoor$ = this.store.select(
      FeatureConfigSelectors.getSidedoorConfig
    ) as Observable<T | undefined>;
    return filterNullAndUndefinedValues
      ? sidedoor$.pipe(filterNullUndefined())
      : sidedoor$;
  }

  clientConfig$(
    filterNullAndUndefinedValues: boolean = true
  ): Observable<ClientConfig> {
    const clientConfig$ = this.store.select(
      FeatureConfigSelectors.getClientConfig
    ) as Observable<ClientConfig>;
    return filterNullAndUndefinedValues
      ? clientConfig$.pipe(filterNullUndefined())
      : clientConfig$;
  }
}
