import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { FeatureConfigFacade } from '@common/data-access-feature-config';
import { CmsDataService, cmsDataUrl } from '@common/data-access-shared';
import { CmsData, CmsPage } from '@common/util-models';
import { Observable } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class QuoteCmsPageResolver implements Resolve<Observable<CmsData>> {
  constructor(
    private cmsDataService: CmsDataService,
    private featureConfigFacade: FeatureConfigFacade
  ) {}

  resolve(): Observable<CmsData> {
    const baseUrl = cmsDataUrl[CmsPage.Quote]?.url;
    return this.featureConfigFacade.clientConfig$().pipe(
      map((clientConfig) => {
        return `${baseUrl}/${'policy-features-'}${
          clientConfig?.policyFeaturesClientIdentifier
        }`;
      }),
      take(1),
      switchMap((quoteCmsPageUrl) =>
        this.cmsDataService.getCmsData({
          id: CmsPage.Quote,
          url: quoteCmsPageUrl,
        })
      )
    );
  }
}
