<header class="header-container" data-hook="app-header">
  <div class="pl-2">
    <img
      *ngIf="!whiteTheme"
      src="assets/images/edf-logo.png"
      class="edf-logo"
      role="img"
      alt="EDF"
      height="48"
    />
    <img
      *ngIf="whiteTheme"
      src="assets/images/edf-colour.svg"
      class="edf-logo"
      role="img"
      alt="EDF"
      height="48"
    />
  </div>

  <div class="header-spacer"></div>
  <div class="header-tag-line">Powered by</div>

  <img
    class="dg-logo mr-2"
    *ngIf="!whiteTheme"
    src="assets/images/dg-logo-all-white.png"
    role="img"
    height="40"
    alt="Domestic & General"
  />
  <img
    class="dg-logo mr-2"
    *ngIf="whiteTheme"
    src="assets/images/dg-logo-colour.png"
    role="img"
    height="40"
    alt="Domestic & General"
  />
</header>
