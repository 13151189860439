import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  CorrelationIdPartialState,
  correlationIdReducer,
  CORRELATION_ID_KEY,
  RestartJourneyEffects,
} from '@common/data-access-shared';
import {
  AppProvidersConfig,
  AppProvidersConfigToken,
  CommonFeatureCoreModule,
  META_REDUCERS,
} from '@common/feature-core';
import { CommonUiSalesComponentsModule } from '@common/ui-sales-components';
import { CommonUiWhitelabelComponentsModule } from '@common/ui-whitelabel-components';
import { ENVIRONMENT } from '@common/util-foundation';
import { SharedUiComponentsCoreModule } from '@domgen/dgx-fe-components-core';
import { HeaderModule } from '@domgen/dgx-ui-whitelabel-components';
import { environment } from '@edf-appliances/util-environment';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { EdfFeatureHeaderModule } from '@edf/feature-header';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedDataAccessNavigationModule } from '@shared/data-access-navigation';
import { EdfAppliancesAppComponent } from './components/edf-appliances-app.component';
import { EdfAppliancesFeatureShellRouterModule } from './edf-appliances-feature-shell-router.module';
import { NAVIGATION_RULES } from './modules/navigation-rules.config';

const modules = [
  EdfAppliancesFeatureShellRouterModule,
  CommonUiSalesComponentsModule,
  HttpClientModule,
  SharedUiComponentsCoreModule,
  HeaderModule,
  CommonUiWhitelabelComponentsModule,
];

@NgModule({
  declarations: [EdfAppliancesAppComponent],
  imports: [
    ...modules,
    CommonModule,
    StoreModule.forRoot(
      {
        [CORRELATION_ID_KEY]: correlationIdReducer,
      } as ActionReducerMap<
        CorrelationIdPartialState & Record<string, unknown>
      >,
      {
        metaReducers: !environment.production
          ? [...META_REDUCERS]
          : [...META_REDUCERS],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    SharedDataAccessNavigationModule.forRoot({
      defaultRedirectUrl: environment.applianceDetailsPage,
      navigationRules: [...NAVIGATION_RULES],
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    CommonFeatureCoreModule,
    EffectsModule.forRoot([RestartJourneyEffects]),
    EdfFeatureHeaderModule,
  ],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
  ],
  exports: [...modules, EdfAppliancesAppComponent],
})
export class EdfAppliancesFeatureShellModule {
  static forRoot(
    navigationConfig: AppProvidersConfig
  ): ModuleWithProviders<EdfAppliancesFeatureShellModule> {
    return {
      ngModule: EdfAppliancesFeatureShellModule,
      providers: [
        { provide: AppProvidersConfigToken, useValue: navigationConfig },
      ],
    };
  }
}
