import { Component } from '@angular/core';
import {
  NavigationError,
  NavigationStart,
  Router,
  Event,
} from '@angular/router';
import { BuildConfigService, ErrorService } from '@common/util-foundation';
import { filter, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'edf-appliances-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'EDF Appliances';

  navigationError$ = this.router.events.pipe(
    filter((allEvents) => !!(allEvents instanceof NavigationError))
  );

  firstNavigation$ = this.router.events.pipe(
    filter((allEvents) => !!(allEvents instanceof NavigationStart)),
    take(1)
  );

  constructor(
    private buildConfig: BuildConfigService,
    private errorService: ErrorService,
    private router: Router
  ) {
    this.navigationError$.pipe(untilDestroyed(this)).subscribe(() => {
      this.errorService.handleError();
    });

    this.firstNavigation$
      .pipe(untilDestroyed(this))
      .subscribe((event: Event) =>
        this.preventRefresh(event as NavigationStart)
      );
  }

  //prevent refresh on (or directly loading) anything other than landing pages
  private preventRefresh(event: NavigationStart) {
    const [baseUrl] = event.url.split('?', 2);
    if (this.buildConfig.config.validLandingPages?.includes(baseUrl)) {
      return;
    }
    this.router.navigateByUrl(this.buildConfig.config.applianceDetailsPage);
  }
}
