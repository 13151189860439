import { BuildConfig, TrustBox } from '@common/util-models';
const privacyLink = {
  section: 'legal',
  label: 'Privacy and Cookies policy',
  href: 'https://www.domesticandgeneral.com/privacy-cookies-policy',
  newWindow: true,
};
export const mockBuildConfig: BuildConfig = {
  header: {
    home: {
      href: 'https://www.domesticandgeneral.com',
      label: 'home',
    },
    products: {
      label: 'Get protected',
      href: 'https://www.sales.athome.domesticandgeneral.com/products',
    },
    repair: {
      label: 'Book a repair',
      href: 'https://www.domesticandgeneral.com/repairs',
    },
    faq: {
      label: 'Help and advice',
      href:
        'https://www.domesticandgeneral.com/content/help-advice-section/faq',
    },
  },
  footer: {
    links: [
      {
        section: 'protect',
        label: 'Household appliances',
        href: 'https://domesticandgeneral.com/shop/en/dg/products/household',
        newWindow: true,
      },
      {
        section: 'protect',
        label: 'Washing machine',
        href: 'https://www.domesticandgeneral.com/insurance/washing-machine',
        newWindow: true,
      },
      {
        section: 'protect',
        label: 'Dishwasher',
        href: 'https://www.domesticandgeneral.com/insurance/dishwasher',
        newWindow: true,
      },
      {
        section: 'protect',
        label: 'Fridge freezer',
        href: 'https://www.domesticandgeneral.com/insurance/fridge-freezer',
        newWindow: true,
      },
      {
        section: 'protect',
        label: 'Tumble dryer',
        href: 'https://www.domesticandgeneral.com/insurance/tumble-dryer',
        newWindow: true,
      },
      {
        section: 'protect',
        label: 'Oven',
        href:
          'https://www.domesticandgeneral.com/products/household/oven-protection',
        newWindow: true,
      },
      {
        section: 'protect',
        label: 'Heating',
        href: 'https://domesticandgeneral.com/shop/en/dg/heating',
        newWindow: true,
      },
      {
        section: 'protect',
        label: 'TV & Audio',
        href: 'https://domesticandgeneral.com/shop/en/dg/products/tv-audio',
        newWindow: true,
      },
      {
        section: 'protect',
        label: 'Photo & Video devices',
        href:
          'https://domesticandgeneral.com/shop/en/dg/products/camera-mobile',
        newWindow: true,
      },

      {
        section: 'more',
        label: 'Get protected',
        routerPath: '/products',
      },
      {
        section: 'more',
        label: 'Book a repair',
        href: 'https://domesticandgeneral.com/appliance-repairs',
        newWindow: true,
      },
      {
        section: 'more',
        label: 'About us',
        href: 'https://domesticandgeneral.com/corporate/about-us',
        newWindow: true,
      },
      {
        section: 'more',
        label: 'Help and advice',
        href: 'https://domesticandgeneral.com/content/help-advice-section/faq',
        newWindow: true,
      },
      {
        section: 'more',
        label: 'Contact us',
        href:
          'https://www.domesticandgeneral.com/content/contact-domestic-general',
        newWindow: true,
      },
      {
        section: 'more',
        label: 'Media centre',
        href:
          'https://domesticandgeneral.com/content/help-advice-section/corporate-information/media-centre',
        newWindow: true,
      },
      {
        section: 'more',
        label: 'Careers',
        href: 'http://careers.domesticandgeneral.com/',
        newWindow: true,
      },
      {
        section: 'useful',
        label: 'Investor relations',
        href: 'https://investors.domesticandgeneral.com/',
        newWindow: true,
      },
      {
        section: 'useful',
        label: 'Regulatory information',
        href:
          'https://domesticandgeneral.com/shop/en/dg/company-information-legal',
        newWindow: true,
      },
      {
        section: 'useful',
        label: 'Charity Partnerships',
        href:
          'https://domesticandgeneral.com/content/help-advice-section/corporate-information/charity-partnerships',
        newWindow: true,
      },
      {
        section: 'useful',
        label: 'Modern Slavery Statement',
        href: 'https://domesticandgeneral.com/shop/en/dg/modern-slavery',
        newWindow: true,
      },
      {
        section: 'useful',
        label: 'Part VII Transfer',
        href: 'https://domesticandgeneral.com/PartVIITransfer',
        newWindow: true,
      },
      {
        section: 'useful',
        label: 'Rogue Trader Guidance',
        href:
          'https://domesticandgeneral.com/content/help-advice-section/faq/received-a-strange-call-',
        newWindow: true,
      },
      {
        section: 'useful',
        label: 'Customer Conduct',
        href: 'https://domesticandgeneral.com/customer-conduct',
        newWindow: true,
      },
      {
        section: 'useful',
        label: 'Coronavirus update',
        href:
          'https://domesticandgeneral.com/content/help-advice-section/faq/coronavirus-covid19-update',
        newWindow: true,
      },
      {
        section: 'legal',
        label: 'Website Terms and Conditions',
        href: 'https://www.domesticandgeneral.com/web-terms-conditions',
        newWindow: true,
      },
      privacyLink,
      {
        section: 'legal',
        label: 'Accessibility policy',
        href: 'https://www.domesticandgeneral.com/accessibility',
        newWindow: true,
      },
    ],
  },
  links: {
    privacy: privacyLink,
  },
  catalogueApi:
    'https://api.catalogue-svc.common.test.test.athome.domgentest.cloud',
  cookieProSrc: 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js',
  cookieProDomainScript: '40f3b527-cf76-4334-82ed-f5d6421d53de-test',
  myAccountLink:
    'https://www.my-account.athome.domesticandgeneral.com/my-account',
  dd_guarantee: 'https://www.domesticandgeneral.com/direct-debit-guarantee',
  domain: 'https://www.sales.test.test.athome.domgentest.cloud/',
  sales: 'https://api.sales.test.test.athome.domgentest.cloud',
  common: 'https://api.esb-service.common.test.test.athome.domgentest.cloud',
  wlClient: 'DandGUK',
  catalogueStore: 'DandGUK',
  action: 'QandB',
  quotePage: '/quote',
  basketApi:
    'https://api.basket-service.common.ci.test.athome.domgentest.cloud/pr-4',
  checkoutLandingPage: '/checkout',
  checkoutDirectDebitPage: '/checkout/direct-debit-payment',
  checkoutPersonalDetailsPage: '/checkout/personal-details',
  recaptchaSiteKey: 'recaptchaSiteKey',
  cms_config: {
    pagesRoot: '/dgx-sales',
    insideCmsEditor: false,
    restUrlBase:
      'https://sit-cms.domesticandgeneral.com/.rest/delivery/pages/v1',
    templateDefinitionBase:
      'https://sit-cms.domesticandgeneral.com/.rest/templateDefinition/v1',
    contentAppBase: 'https://sit-cms.domesticandgeneral.com/.rest/delivery',
    damRawUrl: 'https://sit-cms.domesticandgeneral.com',
    useFallbackCmsContent: false,
  },
  trustPilot: {
    businessUnitId: '4cb3113600006400050dfb02',
    templates: [
      {
        id: 'template-id-miro-000',
        name: TrustBox.MICRO,
      },
      {
        id: 'template-id-slider-999',
        name: TrustBox.SLIDER,
      },
    ],
  },
  postcodeServiceKey: 'ZG99-WD62-FP46-DC55',
  postcodeServiceFind:
    'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws',
  postcodeServiceRetrieve:
    'https://api.addressy.com/Capture/Interactive/Retrieve/v1/json3.ws',
  identityApiUrl: 'https://api.myaccount.test.test.athome.domgentest.cloud',
  identityApiUrlVersion: 'v1',
  personApi:
    'https://api.person-service.common.test.test.athome.domgentest.cloud/v1',
  applianceDetailsPage: '/products',
  checkoutOrderConfirmationPage: '/checkout/order-confirmation',
  worldpay: {
    iframeHelperURL: 'helper.html',
    customisation: {},
  },
  cardPayment: {
    description: 'Sales',
    transactionSource: 'W',
    transactionType: 'W',
  },
  retryBackOffConfig: {
    maxRetries: 1,
    initialInterval: 3000,
    maxInterval: 3000,
  },
  checkoutLoggedInUserPage: '/checkout/logged-in',
  checkoutCardPaymentPage: '/checkout/card-payment',
  selectPaymentPage: '/checkout/select-payment',
  forgotPasswordPage: '/forgot-password',
  error404: {
    contactUs:
      'https://www.domesticandgeneral.com/content/contact-domestic-general',
    home: 'offer',
  },
  offerPage: '/offer',
  renewalPage: '/renewal',
  appRoot: '/',
  multiBasket: false,
  production: false,
  configPath: '/',
};
